

























import { Component, Prop, Vue } from 'vue-property-decorator'
import VsCheckboxGroup from '@/components/VsCheckboxGroup/Index.vue'
import VsRadioGroup from '@/components/VsRadioGroup/Index.vue'
import VsTimeInput from '@/components/VsTimeInput/Index.vue'
import {
    CustomFieldTypeEnum,
    CustomFieldValidationMethodEnum,
    CustomField,
} from '@/utils/customFields'

@Component({
    name: 'VsCustomFieldGenerator',
    components: {
        VsCheckboxGroup,
        VsRadioGroup,
        VsTimeInput,
    },
})
export default class extends Vue {
    @Prop({
        default: false,
        required: false,
        type: Boolean,
    }) stopValidation?: boolean

    @Prop({
        default: null,
        required: true,
    }) customField!: CustomField

    @Prop({
        default: null,
        required: true,
    }) value!: any

    @Prop({
        default: 'medium',
        required: false,
    }) size?: string

    @Prop({
        default: false,
        required: false,
        type: Boolean,
    }) disabled?: boolean

    @Prop({
        default: false,
        required: false,
        type: Boolean,
    }) readonly?: boolean

    @Prop({
        default: false,
        required: false,
        type: Boolean,
    }) labelHidden?: boolean

    get componentType (): string {
        switch (this.customField.type) {
            case CustomFieldTypeEnum.hiddenField:
            case CustomFieldTypeEnum.singleLine:
            case CustomFieldTypeEnum.email:
                return 'vs-input'
            case CustomFieldTypeEnum.dateField:
                return 'vs-date-picker'
            case CustomFieldTypeEnum.timeField:
                return 'vs-time-input'
            case CustomFieldTypeEnum.mobile:
                return 'vs-phone-input'
            case CustomFieldTypeEnum.paragraphText:
                return 'vs-text-area'
            case CustomFieldTypeEnum.multipleChoise:
                return 'vs-radio-group'
            case CustomFieldTypeEnum.dropdown:
                return 'vs-select'
            case CustomFieldTypeEnum.checkboxes:
                return 'vs-checkbox-group'
            case CustomFieldTypeEnum.booleanCheckbox:
                return 'vs-checkbox'
        }
    }

    get props (): any {
        switch (this.customField.type) {
            case CustomFieldTypeEnum.dateField:
                return {
                    autoComplete: false,
                    clearable: true,
                    name: this.customField.id,
                    iconName: 'calendar-select-day',
                    showDropdowns: true,
                    format: 'DD/MM/YYYY',
                    formatOutput: 'YYYY-MM-DD',
                }
            case CustomFieldTypeEnum.hiddenField:
            case CustomFieldTypeEnum.singleLine:
            case CustomFieldTypeEnum.email:
            case CustomFieldTypeEnum.timeField:
                return {
                    autoComplete: false,
                    clearable: true,
                    name: this.customField.id,
                }
            case CustomFieldTypeEnum.paragraphText:
                return {
                    name: this.customField.id,
                    rows: 2,
                    resize: true,
                }
            case CustomFieldTypeEnum.multipleChoise:
                return {
                    name: this.customField.id,
                    radios: this.customField.field_options.map((e: any) => {
                        return {
                            inputValue: e.value,
                            text: e.label,
                            selected: e.selected,
                        }
                    }),
                }
            case CustomFieldTypeEnum.dropdown:
                return {
                    name: this.customField.id,
                    clearable: true,
                    options: this.customField.field_options,
                }
            case CustomFieldTypeEnum.mobile:
                return {
                    name: this.customField.id,
                    autoComplete: false,
                    clearable: true,
                }
            case CustomFieldTypeEnum.booleanCheckbox:
                // eslint-disable-next-line no-case-declarations
                const option = this.customField.field_options[0]
                if (!option) return

                return {
                    inputValue: option.value,
                    text: option.label,
                    selected: option.selected,
                }
            case CustomFieldTypeEnum.checkboxes:
                return {
                    checkboxes: this.customField.field_options.map((e: any) => {
                        return {
                            inputValue: e.value,
                            text: e.label,
                            selected: e.selected,
                        }
                    }),
                }
        }
    }

    get validations (): string[] {
        const validations: string[] = []
        if (!this.customField) return validations
        if (this.customField.validation.required) validations.push('required')
        if (this.customField.type === CustomFieldTypeEnum.email) {
            validations.push('email')
            return validations
        }
        if (this.customField.type === CustomFieldTypeEnum.mobile) {
            validations.push('libphoneNumberMobile')
            return validations
        }

        switch (this.customField.validation.method) {
            case CustomFieldValidationMethodEnum.numbers:
                validations.push('numeric')
                break
            case CustomFieldValidationMethodEnum.letters:
                validations.push('alphaSpaces')
                break
            case CustomFieldValidationMethodEnum.custom:
                if (this.customField.validation.rule) {
                    try {
                        validations.push(`customRegex:${this.customField.validation.rule}`)
                    } catch (e) {
                        console.log(e)
                    }
                }
                break
            case CustomFieldValidationMethodEnum.numbersAndLetters:
                validations.push('alphaNumSpaces')
                break
            case CustomFieldValidationMethodEnum.emailAddress:
                validations.push('email')
                break
            case CustomFieldValidationMethodEnum.mobile:
                validations.push('libphoneNumberMobile')
                break
            case CustomFieldValidationMethodEnum.url:
                validations.push('url')
                break
            // case CustomFieldValidationMethodEnum.date:
            case CustomFieldValidationMethodEnum.time:
                validations.push('timeHhMmSs')
                break
            case CustomFieldValidationMethodEnum.disabled:
            default:
                break
        }

        return validations
    }

    get label () {
        const optionalString = this.customField.validation.required ? ' *' : ` (${this.$t('lists.createContact.optional')})`
        return this.customField.name + optionalString
    }
}
